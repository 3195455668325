import {Button} from '@/Components/Catalyst/button'
import {Dialog, DialogActions, DialogBody, DialogDescription, DialogTitle} from '@/Components/Catalyst/dialog'
import React, {PropsWithChildren, useEffect, useState} from 'react'
import IconButton from "@/Components/Dashboard/UI/IconButton";
import NewMealPlanDialog from "@/Components/Dialogs/NewMealPlanDialog";
import {DescriptionList, DescriptionTerm} from "@/Components/Catalyst/description-list";
import {usePage} from "@inertiajs/react";
import Api from "@/Api";
import {CheckCircleIcon} from "@heroicons/react/24/outline";
import {Heading} from "@/Components/Catalyst/heading";
import {CalendarDateRangeIcon} from "@heroicons/react/16/solid";
import {emitCustomEvent, useCustomEventListener} from "react-custom-events";
import Data = App.Data;
import MealPlanData = App.Data.MealPlanData;

interface RecipeMealPlanDialogProps extends PropsWithChildren {
  recipe: Data.RecipeData
}

interface CardBodyProps {
  recipe: Data.RecipeData
  mealPlan: MealPlanData
}


function CardBody(props: CardBodyProps) {
  const planHasRecipe = (mealPlan: MealPlanData) => mealPlan?.recipeUuids.some(
    (mealPlanRecipeUuid: string) => {
      return mealPlanRecipeUuid === props.recipe.uuid
    }
  )

  const [
    added,
    setRecipeInMealPlan
  ] = useState<boolean>(planHasRecipe(props.mealPlan))

  // Update state when recipe is added or removed from meal plan
  useEffect(() => {
    setRecipeInMealPlan(
      planHasRecipe(props.mealPlan)
    )
  }, [setRecipeInMealPlan, props.mealPlan, props.recipe])

  return (
    <DialogBody className="max-w-lg" key={props.mealPlan.uuid}>
      <DescriptionList
        className="transition-opacity fade ease-in-out cursor-pointer px-2 rounded hover:bg-slate-100"
        onClick={async () => {
          const response = await Api.mealPlan.toggleRecipe(props.mealPlan, props.recipe)

          emitCustomEvent('meal-plan::updated', props.mealPlan)

          setRecipeInMealPlan(response.data.added)
        }}
      >
        <DescriptionTerm>
          <div
            data-slot="text"
            className={'text-lg sm:text-xl font-bold tracking-wide'}
          >
            {
              added
                ? <CheckCircleIcon className='h-7 w-7 mr-4 text-emerald-400 inline-block'/>
                : <CheckCircleIcon className='h-7 w-7 mr-4 text-gray-400 inline-block'/>
            }
            <Heading level={3} className="inline-block">{props.mealPlan.name}</Heading>
          </div>
        </DescriptionTerm>
      </DescriptionList>
    </DialogBody>
  )
}

export default function RecipeMealPlanDialog(props: RecipeMealPlanDialogProps) {
  let [isOpen, setIsOpen] = useState(false)
  const pageProps: any = usePage().props

  const [
    userMealPlans,
    setUserMealPlans
  ] = useState<MealPlanData[]>(pageProps?.auth?.user?.mealPlans ?? [])

  const handleMealPlanCreated = (mealPlanData: MealPlanData) => {
    setUserMealPlans([...userMealPlans, mealPlanData])
  }

  useCustomEventListener('meal-plan::created', (mealPlanData: MealPlanData) => {
    handleMealPlanCreated(mealPlanData)
  }, [userMealPlans, setUserMealPlans])


  return (
    <>
      {!props.children &&
        <IconButton onClick={() => setIsOpen(true)}>
          <CalendarDateRangeIcon aria-hidden="true"/>
        </IconButton>
      }

      {!!props.children &&
        <div onClick={() => setIsOpen(true)}>
          {props.children}
        </div>
      }

      <Dialog open={isOpen} onClose={setIsOpen}>
        <DialogTitle>{props.recipe.name}</DialogTitle>
        <DialogDescription>{userMealPlans ? "Select Meal Plans below" : "Sorry, you don't have active meal plans"}</DialogDescription>

        {userMealPlans &&
          userMealPlans.map(
            mealPlan => <CardBody
              key={mealPlan.uuid}
              recipe={props.recipe}
              mealPlan={mealPlan}
            />
          )
        }

        <DialogActions>
          <NewMealPlanDialog recipeQuickAdd={props.recipe}/>
          <Button plain onClick={() => setIsOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );

}
