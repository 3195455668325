import {Button} from '@/Components/Catalyst/button'
import {Dialog, DialogActions, DialogBody, DialogDescription, DialogTitle} from '@/Components/Catalyst/dialog'
import React, {useState} from 'react'
import NewMealPlanForm from "@/Components/Forms/NewMealPlanForm";
import {useCustomEventListener} from "react-custom-events";

interface NewMealPlanDialogProps {
  recipeQuickAdd?: App.Data.RecipeData
}

export default function NewMealPlanDialog(props: NewMealPlanDialogProps) {
  let [isOpen, setIsOpen] = useState(false)

  useCustomEventListener('meal-plan::created', () => {
    setIsOpen(false)
  })

  return (
    <>
      <Button color='emerald' type="button" onClick={() => setIsOpen(true)}>
        Create a new meal plan
      </Button>
      <Dialog open={isOpen} onClose={setIsOpen}>

        <DialogTitle>New Meal Plan</DialogTitle>

        <DialogDescription>
          A good meal plan starts with a good name
        </DialogDescription>

        <DialogBody>
          <NewMealPlanForm recipeQuickAdd={props.recipeQuickAdd}/>
        </DialogBody>

        <DialogActions>
          <Button plain onClick={() => setIsOpen(false)}>
            Close
          </Button>
        </DialogActions>

      </Dialog>
    </>
  )

}
